.simple-keyboard {
  max-width: 100vw;
}

.keyboard-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  padding: 0 1.5rem;
  height: 90.75%;
}

/* .hg-theme-default {
  background: 'red'
} */
.hg-theme-default .hg-button {
  background: #000;
}