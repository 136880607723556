.TVKeyboard {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    animation: 0.3s opacity 1 forwards;
  }

  .keyboard {
    position: absolute;
    width: 820px;
    min-height: 640px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    overflow: hidden;
    color: #fff;
    animation: 0.5s translateY 1 forwards;
  }

  .header {
    display: flex;
    align-items: center;
    height: 65px;
    width: 100%;
    padding: 0 25px;

    img {
      max-width: 180px;
      max-height: 80%;
    }
  }

  .container {
    width: 723px;
    margin: 0 auto;

    .input {
      color: #fff;


      p {
        margin-top: 26px;
        font-size: 25px;
        margin-bottom: 6px;
      }

      .value {
        position: relative;
        display: flex;
        width: 100%;
        height: 60px;
        font-size: 23px;
        padding: 0 15px;
        box-sizing: border-box;
        background: none;
        border-width: 2px;
        border-style: solid;
        border-radius: 5px;
        color: #fff;
        transition: .3s;

        span {
          display: flex;
          justify-content: flex-end;
          line-height: 60px;
          margin: 0;
          letter-spacing: 1;
          max-width: 100%;
          overflow: hidden;
          text-align: right;

          &:nth-of-type(2) {
            justify-content: flex-start;
          }
        }

        &:focus {
          outline: none;
        }

        .show {
          position: relative;
          opacity: 0.8;
          font-size: 30px;
          line-height: 50px;
          animation: pulse 0.9s infinite;
          width: 0;
          padding: 0;
          width: 0;
          right: 2px;
        }
      }
    }

    .keypads {
      display: -webkit-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;

      .key {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        margin-right: 9px;
        margin-bottom: 9px;
        text-align: center;
        line-height: 62px;
        border-radius: 5px;
        font-size: 20px;
        transition: background 0.5s;
        cursor: pointer;

        &:nth-of-type(37),
        &:nth-of-type(38) {
          svg {
            margin-right: 0;
            margin-left: 20px;
          }
        }

        svg {
          width: 30px;
          margin-right: 20px;

          circle,
          path {
            stroke: #fff;
          }

          path {
            fill: #fff;
          }
        }

        &:nth-of-type(10),
        &:nth-of-type(20),
        &:nth-of-type(30),
        &:nth-of-type(35),
        &:nth-of-type(38) {
          margin-right: 0;
        }

        &.CAPS,
        &.DELETE,
        &.CLEAR {
          width: 137px;
        }

        &.SPACE,
        &.PREV,
        &.NEXT {
          width: 210px;
        }

        &.ENTER {
          width: 284px;
        }
      }
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes translateY {
  from {
    transform: translate(-50%, -100%);
    opacity: 0;
  }

  to {
    transform: translateY(-50%, -50%);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }

  35% {
    opacity: 0.8;
  }

  50% {
    opacity: 0;
  }

  85% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}