@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

:root {
  --theme-color-black: #000000;
  --theme-color-white: #ffffff;
  --theme-color-red: #ff0000;
  --theme-color-body-bg: #0d0d0d;
  --theme-color-text: #ffffff;
  --theme-color-primary: #32ffe8;
  --theme-color-img-bg: #333333;
  --theme-color-topmenu-text: #b4b7bb;
  --theme-color-sidemenu-text: #b4b7bb;
  --theme-color-sidemenu-hover-text: #2c2d31;
  --theme-color-sidemenu-focused-text: #b4b7bb;
  --theme-color-sidemenu-focused-bg: #2c2d31;
  --theme-color-input-bg: #2c2d31;
  --theme-color-input-text: #b4b7bb;
  --theme-color-transparent: rgba(0, 0, 0, 0);
  --theme-color-duration-bg: rgba(0, 0, 0, 0.7);
  --theme-color-image-shadow: rgba(50, 255, 232, 0.75);
}

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

*:focus {
  outline: none;
}

body {
  background-color: var(--theme-color-body-bg);
  color: var(--theme-color-text);
  font-family: 'Roboto', sans-serif !important;
  padding: 0 !important;
  margin: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
div,
button,
li {
  font-family: 'Roboto', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
button,
li {
  color: var(--theme-color-text);
}

a,
a:hover {
  opacity: 1 !important;
}

a.link {
  cursor: pointer;
}

.hide {
  display: none !important;
}

.empty-container {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
}

.splashscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader .loader-text {
  width: 10.16vw;
  font-size: 2.5rem;
  line-height: 3rem;
  color: var(--theme-color-primary);
  font-weight: 600;
}

.loader .loader-text .dots {
  width: 0.625rem;
  height: 0.625rem;
  font-size: 0.625rem;
  line-height: 0.625rem;
  background-color: var(--theme-color-primary);
  border-radius: 50%;
  margin-right: 0.3125rem;
}

.loader .loader-text .dots.dot-dot1 {
  margin-left: 0.3125rem;
}

.live-video-container {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.bg-player-overlay-opacity {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.58);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.app-container {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}

.top-navigation {
  position: fixed;
  left: 3.75rem;
  right: 3.75rem;
  top: 2.375rem;
  height: 9.26vh;
  background-color: none !important;
  z-index: 8888;
}

.top-navigation .nav-left,
.top-navigation .nav-right {
  flex: auto;
}

.top-navigation .brand-logo img {
  width: 8.125vw;
  height: 4.75vw;
  margin-right: 1.875rem;
}

.top-navigation .tabs .menu-item {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 0.1875rem solid var(--theme-color-transparent);
  color: var(--theme-color-topmenu-text);
}

.top-navigation .tabs .menu-item.active {
  color: var(--theme-color-primary);
}

.top-navigation .tabs .menu-item.focused,
.top-navigation .tabs .menu-item:hover {
  border-bottom: 0.1875rem solid var(--theme-color-primary);
}

.top-navigation .icon {
  border-bottom: 0.1875rem solid var(--theme-color-transparent);
}

.top-navigation .icon.active img {
  filter: drop-shadow(0 0 var(--theme-color-primary));
}

.top-navigation .icon.focused,
.top-navigation .icon:hover {
  border-bottom: 0.1875rem solid var(--theme-color-primary);
}

.top-navigation .icon img {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 1.875vw;
  height: 1.875vw;
}

.page-container {
  display: flex;
  height: 90.75%;
  width: 100%;
  overflow: hidden;
  overflow: hidden;
  z-index: 9999;
  top: 1.75rem;
  position: fixed;
  margin-top: 49.375rem;
}

.page-container.has-show-bg-video {
  transition: margin-top 0.5s ease-in-out;
}

.page-container.main-no-video-show {
  margin-top: 7.1875rem !important;
}

.page-container .side-menu {
  width: 19.8%;
  top: 14.8125rem;
  padding-left: 9.0625rem;
  justify-content: flex-start;
}

.page-container .page-content {
  width: 100%;
  justify-content: flex-end;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.page-container .side-menu,
.page-container .page-content {
  flex: auto;
}

.page-container .side-menu .side-nav a {
  width: 19.8%;
  height: 7.87%;
  align-items: center;
  display: flex;
  color: var(--theme-color-sidemenu-text) !important;
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin-bottom: 0.3125rem;
  font-weight: 600;
  padding-left: 1.5rem;
  padding-right: 0.625rem;
}

.page-container .side-menu .side-nav a.active,
.page-container .side-menu .side-nav a.focused {
  background-color: var(--theme-color-sidemenu-focused-bg);
  color: var(--theme-color-sidemenu-focused-text) !important;
}

.page-container .side-menu .side-nav a:hover {
  background-color: var(--theme-color-primary);
  color: var(--theme-color-sidemenu-hover-text) !important;
}

.page-container .side-menu .side-nav a span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-container .page-content.has-bg-video {
  height: 137%;
}

.settings-container {
  display: flex;
  margin-top: 9.375rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.settings-qrcode {
  justify-content: flex-start;
  height: 21.875rem;
  width: 21.875rem;
}

.settings-section {
  justify-content: flex-end;
  margin-left: 3.125rem;
}

.settings-section .title {
  width: 28.65vw;
  font-size: 1.75rem;
  line-height: 2.75rem;
  margin-top: 5rem;
  margin-bottom: 1.875rem;
}

.settings-section .link {
  font-size: 2.25rem;
  line-height: 3rem;
  color: var(--theme-color-primary);
}

.horizontal-list {
  position: relative;
  width: 100%;
  margin-bottom: 3.125rem;
}

.horizontal-list .grid-title {
  position: absolute;
  font-size: 1.875rem;
  line-height: 2.625rem;
  top: 0.75rem;
  font-weight: 600;
  margin-left: 7.1875rem;
}

.horizontal-list .media-scroller {
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding-right: 6rem;
  position: relative;
  left: 2px;
}

.media-element.prj-element {
  padding-right: 4.25rem !important;
}

.horizontal-list .media-element {
  position: relative;
  padding: 2.5rem 7.1875rem;
  margin-right: 2.6875rem;
}

.horizontal-list .media-element.portrait {
  position: relative;
  margin-top: 1.25rem;
  width: 18.25rem;
  height: 27.375rem;
}

.horizontal-list .media-element .img {
  position: relative;
  width: 100%;
  height: 30vh;
  border-radius: 1.25rem;
  margin-bottom: 0.625rem;
  cursor: pointer;
  overflow: hidden;
}

.horizontal-list .media-element.portrait .img {
  width: 18.25rem;
  height: 27.375rem;
}

.horizontal-list .media-element .img .img-container {
  width: 100%;
  height: 100%;
  background-color: var(--theme-color-img-bg);
}

.horizontal-list .media-element.portrait .img .img-container {
  width: 18.25rem;
  height: 27.375rem;
}

.horizontal-list .media-element .img .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.horizontal-list .media-element.portrait .img .img-container img {
  inline-size: 100%;
  aspect-ratio: 2/3;
  object-fit: cover;
}

.horizontal-list .media-element.active .img,
.horizontal-list .media-element.focused .img,
.horizontal-list .media-element:hover .img {
  box-shadow: 0 0 0.625rem 0.625rem var(--theme-color-image-shadow);
}

.horizontal-list .media-element .title {
  font-size: 1.875rem;
  line-height: 2.125rem;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
}

.media-element.landscape.prj-element .img {
  width: 35rem;
  height: 19.6875rem;
}

.media-element.landscape.prj-element .img .img-container {
  width: 35rem;
  height: 19.6875rem;
}

.horizontal-list .media-element .sub-title {
  font-size: 1.25rem;
  width: 33.25rem;
  line-height: 1.75rem;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-list {
  width: 100%;
  margin-left: 19.64vw;
}

.grid-list .media-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 3.125rem;
  margin-left: 13.75rem;
  position: absolute;
}

.side-menu {
  position: fixed !important;
}

.grid-list .media-element {
  position: relative;
  width: 35.625rem;
  margin-right: 3.75rem;
  margin-bottom: 3.75rem;
}

.grid-list .media-element .img {
  position: relative;
  width: 100%;
  height: 20rem;
  border-radius: 1.25rem;
  margin-bottom: 0.625rem;
  cursor: pointer;
  border: 0.3125rem solid var(--theme-color-transparent);
  overflow: hidden;
}

.grid-list .media-element .img .img-container {
  width: 100%;
  height: 100%;
  background-color: var(--theme-color-img-bg);
}

.grid-list .media-element .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-list .media-element.active .img,
.grid-list .media-element.focused .img,
.grid-list .media-element:hover .img {
  border: 0.3125rem solid var(--theme-color-primary);
  box-shadow: 0 0 0.625rem 0.625rem var(--theme-color-image-shadow);
}

.grid-list .media-element .title {
  font-size: 1.875rem;
  line-height: 2.125rem;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
}

.grid-list .media-element .sub-title {
  font-size: 1.5rem;
  line-height: 1.75rem;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.media-element .img .video-duration {
  position: absolute;
  bottom: 0.9375rem;
  right: 1.875rem;
  text-align: right;
  font-size: 1.875rem;
  background-color: var(--theme-color-duration-bg);
  color: var(--theme-color-white);
  padding: 0.1875rem 0.375rem;
  border-radius: 0.3125rem;
}

.media-element .img .progress-wrapper {
  position: absolute;
  bottom: 0.3125rem;
  left: 3%;
  width: 94%;
}

.media-element .img .progress-wrapper .progress-bar {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 0;
  margin: 0 auto;
}

.media-element .img .progress-wrapper .progress-bar-fill {
  display: block;
  height: 0.3125rem;
  background-color: var(--theme-color-primary);
}

.fullscreen-container-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--theme-color-body-bg);
  z-index: 88888;
}

.fullscreen-container-fixed .bg-poster {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(1.25rem);
  -webkit-filter: blur(1.25rem);
}

.fullscreen-container-fixed .bg-poster-layer {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fullscreen-container-fixed .back-to-page {
  position: relative;
  top: 1.875rem;
  left: 6.25rem;
  color: var(--theme-color-white);
  cursor: pointer;
  display: none;
}

.fullscreen-container-fixed .video-details {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 11.75rem;
  margin-bottom: -4.375rem;
  height: 65.8vh;
  scroll-behavior: smooth;
}

.fullscreen-container-fixed .video-details .details {
  width: 65%;
  padding-top: 6.8125rem;
  padding-left: 6.25rem;
}

.fullscreen-container-fixed .video-details .details .title {
  font-size: 3.8125rem;
  line-height: 4.875rem;
  font-weight: 500;
  margin-top: 0.625rem;
}

.fullscreen-container-fixed .video-details .details .description {
  font-size: 1.5625rem;
  line-height: 2.125rem;
  margin-top: 0.625rem;
  overflow: hidden !important;
  height: 16.11vh;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  white-space: pre-wrap;
}

.fullscreen-container-fixed .video-details .details .buttons {
  display: flex;
  justify-content: space-between;
  height: 9.26vh;
  width: 44.27vw;
  margin-top: 0.3125rem;
}

.fullscreen-container-fixed .video-details .details .buttons .play-btn {
  background-color: var(--theme-color-white);
  color: var(--theme-color-body-bg);
  margin-top: 1.25rem;
  line-height: 2.25rem;
  display: flex;
  align-items: center;
  font-size: 1.875rem;
  width: 20.83vw;
  height: 8.89vh;
  border-radius: 1rem;
}

.fullscreen-container-fixed .video-details .details .buttons .play-btn:hover {
  background-color: var(--theme-color-primary);
  color: var(--theme-color-body-bg);
}

.fullscreen-container-fixed .video-details .details .buttons .play-btn img {
  margin-right: 0.9375rem;
  width: 2.8125rem;
  height: 2.8125rem;
}

.fullscreen-container-fixed .video-details .image {
  width: 35%;
  padding-right: 6.25rem;
  text-align: right;
}

.fullscreen-container-fixed .video-details .image-wrap {
  width: 30.3125rem;
  height: 18.5rem;
  background-color: var(--theme-color-img-bg);
  border-radius: 1.25rem;
  float: right;
  overflow: hidden;
  margin-top: 5.25rem;
}

.fullscreen-container-fixed .video-details .image-wrap.portrait {
  width: 25rem;
  height: 37.5rem;
  margin-top: 0;
}

.fullscreen-container-fixed .video-details .image .image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fullscreen-container-fixed .more-like-this {
  position: relative;
  width: 100%;
  top: 5.375rem;
}

.fullscreen-container-fixed .more-like-this .title {
  font-size: 1.875rem;
  line-height: 2.375rem;
  font-weight: 700;
  margin-left: 5.9375rem;
}

.fullscreen-container-fixed .more-like-this .list {
  display: flex;
  overflow-x: auto;
  margin-left: -1.875rem;
  height: 50.28vh;
  padding: 0 8rem;
}

.fullscreen-container-fixed .more-like-this .list .video {
  position: relative;
  min-width: 32.875rem;
  max-width: 32.875rem;
  border-radius: 1.25rem;
  cursor: pointer;
  margin-right: 1.25rem;
}

.fullscreen-container-fixed .more-like-this .list .video.portrait {
  min-width: 18.75rem;
  height: 28.125rem;
  margin-right: 1.25rem;
}

.fullscreen-container-fixed .more-like-this .list .video:hover {
  border: 0.3125rem solid var(--theme-color-primary);
}

.fullscreen-container-fixed .more-like-this .list .video img {
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  object-fit: cover;
}

.video-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

.video-container #video-player,
.video-container #video-player .video-js {
  width: 100%;
  height: 100%;
}

.video-container .close-video-container {
  position: fixed;
  top: 1.25rem;
  right: 1.25rem;
  z-index: 99999;
  background-color: var(--theme-color-white);
  padding: 0.3125rem 0.625rem;
  display: none;
}

.video-container .close-video-container:hover {
  background-color: var(--theme-color-white);
  opacity: 1;
}

.video-container .close-video-container img {
  width: 1.125rem;
}

.search-bar {
  width: 80%;
  height: 5.6vh;
  margin: 0.875rem auto;
}

.search-bar input {
  height: 5.6vh;
  line-height: 3.75rem;
  font-size: 1.875rem !important;
  color: var(--theme-color-input-text);
  background-color: var(--theme-color-input-bg);
}

.search-results {
  padding: 1.25rem 0 3.125rem 0;
  width: 100%;
}

.search-results .searching {
  width: 100%;
  text-align: center;
  color: var(--theme-color-text);
  font-size: 1.75rem;
  margin-top: 15.625rem;
}

.search-results .loading {
  margin-top: 15.625rem;
}

.video-player-screen {
  height: 100% !important;
  width: 100% !important;
}

.player-overlay {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.player-bottom-bar {
  background-color: var(--theme-color-duration-bg);
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 1.875rem;
  padding-top: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 3rem;
}

.player-text {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-self: baseline;
}

.player-watching-text {
  font-size: 1.875rem;
  margin-bottom: 0.3125rem;
}

.player-title {
  font-size: 2.375rem;
  margin-bottom: 0.9375rem;
}

.player-progress-track {
  display: flex;
  width: 80.73vw;
  height: 0.625rem;
  background-color: rgba(255, 255, 255, 0.24);
  border-radius: 1.5625rem;
}

.player-timer {
  display: flex;
  color: #ffffff;
  font-size: 1.5625rem;
  margin-top: 1.875rem;
  align-self: baseline;
  align-items: center;
}

.player-time {
  display: flex;
  color: #ffffff;
  font-size: 1.3125rem;
  margin: 0 1.25rem;
  width: 5.625rem;
}

.player-progress-bar {
  background-color: #ffffff;
  border-radius: 1.5625rem;
}

.player-button-group {
  display: flex;
  flex-direction: row;
  align-self: center;
  position: absolute;
  width: 80.73vw;
  justify-content: center;
}

.media-btn {
  background-color: transparent;
}

.media-btn.focused {
  background-color: var(--theme-color-primary);
}

.rewind.media-btn,
.playpause,
.fastforward {
  display: flex;
  align-items: center;
}

.media-btn-img {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0.625rem 1.25rem;
}

.vjs-progress-control.vjs-control {
  display: none;
}

.vjs-control-bar {
  display: none !important;
}

button#resume-btn.play-btn.prj-element.focused {
  background-color: var(--theme-color-primary);
}

button#play-btn.play-btn.prj-element.focused {
  background-color: var(--theme-color-primary);
}

div.video.prj-element {
  border: 0.75rem solid transparent !important;
}

div.video.prj-element.focused img {
  box-shadow: 0 0 0.625rem 0.625rem var(--theme-color-image-shadow);
}

.player-overlay {
  background-color: none !important;
}

.image-wrap.landscape {
  width: 35rem;
  height: 19.6875rem;
}

.video.prj-element.landscape {
  width: 35rem;
  height: 19.6875rem;
}

.image-wrap.landscape {
  width: 35rem !important;
  height: 19.6875rem !important;
}

.video.prj-element.landscape {
  width: 35rem !important;
  height: 19.6875rem !important;
  min-width: 35rem !important;
}

video.prj-element.landscape img {
  width: 35rem !important;
  min-width: 35rem !important;
  height: 19.6875rem !important;
}

.video-details-main-div {
  overflow: hidden;
  height: 100%;
}

.media-element.landscape.prj-element {
  padding-top: 3.6875rem;
  padding-bottom: 0;
  padding-right: 0;
  margin-right: -7.6875rem;
}

.list-container {
  display: flex;
}

.movies {
  height: 33.125rem;
}

div.video.prj-element img:hover {
  box-shadow: 0 0 0.625rem 0.625rem var(--theme-color-image-shadow);
}

.side-nav .prj-element.focused {
  background-color: var(--theme-color-primary) !important;
  color: var(--theme-color-sidemenu-hover-text) !important;
}

.side-nav .prj-element.focused span {
  color: var(--theme-color-sidemenu-hover-text) !important;
}

.video-rating {
  font-size: 1.75rem;
  margin-bottom: 1.25rem;
}

.play-btn.small.prj-element.focused {
  width: 11.5625rem !important;
}

.play-btn.small.prj-element {
  width: 11.5625rem !important;
}

.grid-list .media-element.landscape.prj-element {
  margin-right: 4.6875rem !important;
}

.media-scroller.video .overlay-grid {
  position: absolute;
  transition: 0.5s ease;
  width: 35.1875rem;
  height: 8.0625rem;
  bottom: 0;
  left: -0.1875rem;
  background: linear-gradient(to bottom, transparent 0%, black 100%);
  opacity: 1;
}

.page-container.search-page {
  margin-top: 8.4375rem !important;
}

.video.media-element.prj-element {
  padding-top: 1.1875rem !important;
}

.video.prj-element.media-element.portrait {
  padding-top: 0 !important;
}

.more-like-this-horizontal-list {
  padding-right: 1px;
}

.more-like-this-list-container {
  position: relative;
  left: -6.375rem;
}

.loader.loader-fix-pos {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 6.0625rem;
  left: -0.8125rem;
}

.more-like-this .horizontal-list .media-element.portrait {
  margin-top: 0.75rem !important;
}

.closed-caption {
  position: absolute;
  margin-left: 30vw;
}

.caption-container {
  position: absolute;
  background-color: var(--theme-color-input-text);
  right: 0.9375rem;
  bottom: 4.0675rem;
  width: 12.5rem;
  min-height: 6.25rem;
  border-radius: 0.3125rem;
}

.caption-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1.5rem;
  padding: 0.9375rem;
}

.caption-item {
  display: flex;
  justify-content: center;
}

.caption-item-selected {
  background-color: var(--theme-color-black);
}

.caption-item:hover {
  background-color: var(--theme-color-primary);
}

.show {
  display: block !important;
}

.video-js .vjs-ads-time {
  font-size: 1.3125rem;
  color: var(--theme-color-white);
}

.video-js .vjs-ads-info-container {
  background-color: var(--theme-color-image-shadow);
  padding: 0.3125rem;
}

.video-js .vjs-ads-timeline-container {
  width: 99%;
  margin-left: 0.45%;
}

.video-js .vjs-ads-pre-skip {
  background-color: var(--theme-color-image-shadow);
}

.video-js .vjs-ads-time::before {
  color: var(--theme-color-white);
}